@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';

/*
	Dimension by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* BG */

	#bg {
		body.is-preload & {
			&:before {
				background-color: transparent;
			}
		}
	}

/* Header */

	#header {
		body.is-preload & {
			> * {
				opacity: 1;
			}

			@include vendor('filter', 'none');

			.content {
				.inner {
					max-height: none;
					padding: 3rem 2rem;
					opacity: 1;
				}
			}
		}
	}

/* Main */

	#main {
		article {
			opacity: 1;
			margin: (_size(element-margin) * 2) 0 0 0;
		}
	}
